<template>
  <div class="fundtransfer">
    <p class="path">Frequently Asked Questions > TayoCash Services > Fund Transfer</p>
    <h3>Fund Transfer</h3>
    <h4 id="ft1">How do I transfer funds to another TayoCash member?</h4>
    <div class="paragraph">
      <ol>
        <li>Open your TayoCash app and tap the <span class="vtxt-align">Fund Transfer</span> icon.</li>
        <li>You may choose to input the <span class="vtxt-align">Mobile Number</span> or scan the <span class="vtxt-align">QR Code</span> of the recipient.</li>
        <li>Enter the amount and tap <span class="vtxt-align">Send Money</span>.</li>
        <li>Review the details of your transaction and tap <span class="vtxt-align">Confirm</span>.</li>
        <li>An acknowledgement receipt will be given to you once the transaction has been processed.</li>
      </ol>
    </div>

    <h4 id="ft2">How do I Transfer Funds to Other TayoCash Member via Instapay?</h4>
    <div class="paragraph">
      <p>You may transfer funds to any TayoCash Member via InstaPay by following the steps below:</p>

      <ol>
        <li>Open your TayoCash app and click the Quick Scan icon found at the upper right corner of your dashboard.</li>

        <li>You may choose to scan or import QR PH Code. The details of the transaction will automatically be filled out in the TayoCash app.</li>

        <li>Click Review and check the details of the transaction. Once you’ve validated all information click Confirm.</li>

        <li>Upon the successful transfer, you may save the Transaction Receipt provided in the TayoCash app. You will also receive an SMS notification from TayoCash confirming the transaction.</li>
      </ol>
    </div>

    <h4 id="ft3">Is there a cap or limit to the amount of funds I can transfer?</h4>
    <div class="paragraph">
      <p>The amount you can transfer depends on your TayoCash Membership wallet limit.</p>

      <p>Click <a class="link" href="/faq/membership">here</a> to learn about the corresponding wallet limit for each TayoCash Membership type.</p>
    </div>

    <h4 id="ft4">I transferred funds to another TayoCash Member more than the intended amount. What do I do?</h4>
    <div class="paragraph">
      <p>
        If you transferred funds more than the intended amount, kindly coordinate with the TayoCash Member who received the fund transfer to return the excess fund. Please make sure that all transaction details are correct before confirming the fund transfer.
      </p>
    </div>

    <h4 id="ft5">I transferred funds to the wrong TayoCash account. What do I do?</h4>
    <div class="paragraph">
      <p>
        Unfortunately, we cannot refund when you transfer to the wrong TayoCash account. Kindly request the TayoCash Member who wrongly received the funds to return the funds to you. If your request is refused and if you like us to assist you in your further discussions with the said TayoCash Member, you may <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website, or email us at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>.
      </p>
    </div>

    <h4 id="ft6">I’m still experiencing the same problem or I have other concerns with the Fund Transfer Service. What do I do?</h4>
    <div class="paragraph">
      <ol>
        <li><router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
          <ul class="loweralpha">
            <li>Select <span class="vtxt-align">Fund Transfer Transaction</span></li>
            <li>Input the following details in the text field:
              <ul>
                <li>Amount</li>
                <li>TayoCash Number of the recipient</li>
                <li>Date and Time of Transaction</li>
                <li>Transaction Reference Number</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:

          <ul class="loweralpha">
            <li>Email Subject: Fund Transfer Transaction Issue</li>
            <li>Email Content:
              <ul>
                <li>Amount</li>
                <li>TayoCash Number of the recipient</li>
                <li>Date and Time of Transaction</li>
                <li>Transaction Reference Number</li>
                <li>Screenshot of the error message (If applicable)</li>
                <li><a class="link" href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Transaction Dispute Form</a></li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        A TayoCash Customer Care Representative will get back to you within 48 hours after you have submitted the ticket.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fundtransfer'
}
</script>

<style scoped lang='scss'></style>
